$(document).ready(function() {
  /* =====  NEWSLETTER  ====== */
  $('.newsletter').each(function(i, el){
    $(this).on('submit', 'form', function(e){
      e.preventDefault();

      var $el = $(this).closest('.newsletter');
      var $form = $(this);
      var $feedback = $('.feedback', $el).hide();

      $.ajax({
        url: $form.attr('action'),
        data: $form.serialize(),
        dataType: 'json',
        type: 'post',
        success: function(data){
          $feedback.hide();
          if(data.status == 'success'){
            $feedback.html(data.message);
            $feedback.addClass('text-success').fadeIn(400);
          }else if(data.status == 'error'){
            $feedback.html(data.message);
            $feedback.addClass('text-danger').fadeIn(400);
          }
        }
      });
    });
  });

  /* =====  MOBILE MENU TOGGLE  ====== */
  $(".offCanvasToggler").click(function(event) {
    event.preventDefault();
    $(".off-canvas-nav").toggleClass("active");
    $(".fullscreen-overlay").toggleClass("active");
    $("html, body").toggleClass("no-scrolling");
  });

  /*======  ACTIONS  ======*/

  /*----------  PRODUCT MENU DROPDOWN  ----------*/

  $(".productenDropdown").hover(
    function() {
      $(".dropdown-arrow", this).addClass("active");
    },
    function() {
      $(".dropdown-arrow", this).removeClass("active");
    }
  );

  /*----------  CATEGORY HOVER  ----------*/

  $(".categories").hover(
    function() {
      $(".categories .products-item").addClass("hovered");
    },
    function() {
      $(".categories .products-item").removeClass("hovered");
    }
  );

  /*----------  NEWS ITEMS HOVER  ----------*/

  $(".news-items").hover(
    function() {
      $(".news-items .news-item").addClass("hovered");
    },
    function() {
      $(".news-items .news-item").removeClass("hovered");
    }
  );

  /*======  SLIDERS  ======*/

  /*----------  HOME SLIDER  ----------*/

  $(".banner-slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    fade: true,
    cssEase: 'linear'
  });

  /*----------  WEIGHT SLIDER  ----------*/

  $(".weight-slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    infinite: true
  });

  /*----------  ABOUT US SLIDER  ----------*/

  $(".about-slider .slides").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    infinite: true
  });

  $(".model-slider").slick({
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    arrows: false,
    infinite: true
  });
});

$(window).scroll(function() {
  var nav = $(".navbar");
  var top = 200;
  if ($(window).scrollTop() >= top) {
    nav.addClass("scrolled");
  } else {
    nav.removeClass("scrolled");
  }
});



  /*----------  LOAD MORE NEWS  ----------*/

var offset = $('#results').children().length;
var limit = $('#results').data('limit');
$('#load-more').on('click', function(e){
  e.preventDefault();
  $.ajax({
    data: {offset : offset, limit : limit},
    dataType: 'json',
    type: 'post',
    success: function(data){
      e.preventDefault();
      $('#results').append(data.html);
      offset += limit;
      if(offset >= data.total){
        $('#load-more').remove();
      }

    }
  });
});
